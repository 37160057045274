.ticket-container {
	.create-ticket-btn {
		justify-content: center !important;
		align-items: center !important;
		padding: 5px 20px !important;
		background: #3340b6 !important;
		border-radius: 4px !important;
		color: #ffffff !important;
		border: none;

		&:hover {
			color: #fff;
			box-sizing: border-box;
			box-shadow: 0 0 0 3px #ced1f3;
		}

		&[disabled] {
			background-color: #f5f5f5 !important;
			color: rgba(0, 0, 0, 0.25) !important;
			border-color: #d9d9d9 !important;

			&:hover {
				color: rgba(0, 0, 0, 0.25) !important;
				border-color: #d9d9d9 !important;
				background-color: #f5f5f5 !important;
			}
		}
	}

	.input-layout {
		box-sizing: border-box !important;
		border-radius: 4px !important;

		&::-webkit-input-placeholder {
			font-family: 'Roboto', sans-serif;
		}

		&.ant-input {
			border-radius: 4px !important;
			padding-bottom: 4px;
		}

		&.ant-input-affix-wrapper ::-webkit-input-placeholder {
			font-family: 'Roboto', sans-serif;
			font-size: 12px !important;
		}

		.ant-input-suffix {
			font-size: 12px !important;
		}
	}

	.select-layout {
		.ant-select-selector {
			border: 1px solid #c7c7c7;
			box-sizing: border-box !important;
			border-radius: 4px !important;

			.ant-select-selection-placeholder {
				font-weight: 400 !important;
				font-family: Roboto, sans-serif;
				color: #bdbdbd !important;
				font-size: 14px !important;
			}
		}
	}

	.cancel-create-btn {
		border: 1px solid #c7c7c7 !important;
		box-sizing: border-box !important;
		border-radius: 4px !important;
		font-size: 14px !important;
		font-weight: 400;
		font-family: Roboto, sans-serif;
		color: #1a1a1a;

		&:hover {
			background-color: #707070;
			color: white;
			border: 1px solid #707070;
		}
	}

	.text-area-layout {
		box-sizing: border-box !important;
		border-radius: 4px !important;

		.ant-input {
			box-sizing: border-box !important;
			border-radius: 4px !important;
		}

		&::-webkit-input-placeholder {
			font-weight: 400 !important;
			font-family: Roboto, sans-serif;
			color: #bdbdbd !important;
			font-size: 14px !important;
		}
	}

	.horizontal-line {
		display: block;
		width: 100%;
		border: 1px solid #ebebeb;
	}

	.create-ticket-form-custom {
		.ant-form-item {
			margin-bottom: 12px;
			.ant-form-item-label {
				margin-right: 16px;
				label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
					content: '';
				}
			}
		}
	}

	.self-center {
		align-self: center;
	}
	.tickets-total-count {
		color: #ffa300;
	}

	.claim-upload-file {
	}
}

.ticket-filter-container {
	.search-btn {
		background: #3340b6;
		border-radius: 4px;
		font-size: 14px !important;
		font-family: Roboto, sans-serif;
		color: #ffffff;
		padding: 4px 29px;
		border: none;
		&:hover {
			color: #fff;
			box-sizing: border-box;
			box-shadow: 0 0 0 3px #ced1f3;
		}
		&:focus {
			border-color: transparent;
		}
	}

	.item {
		font-family: 'Roboto', sans-serif;
		// padding: 1px 10px;
		line-height: 20px;
		font-size: 12px;
		border-radius: 16px;
		padding: 4px 16px;
		border: none !important;
		font-weight: 500;
		margin: 0;
		white-space: nowrap;
		cursor: pointer;
		margin-right: 12px;
		margin-bottom: 12px;
		background-color: #f6f6f6;
		font-weight: 500;
		display: flex;
		align-items: center;

		color: #5a5a5a;
		&:hover {
			background-color: #ebebeb;
		}
		&.active {
			background-color: #faa200;
			color: white;
		}
	}

	.claim-filter-title {
		display: flex;
		&:after {
			opacity: 1;
			content: '';
			width: calc(100% - 160px);
			border-bottom: 1px solid #ebebeb;
			display: block;
			align-self: center;
			margin-left: 10px;
		}
	}
	.claim-filter-form-group {
		display: flex;
		align-items: center;
		& > div {
			margin-right: 6px;
		}

		.claim-filter-form-control {
			display: flex;
			flex-wrap: wrap;
		}
	}
}

.ticket-list {
	thead[class*='ant-table-thead'] th {
		// padding: 7px 12px !important;
		border: none;
		:before {
			content: none !important;
			display: none;
		}
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		display: none;
	}
	.ticket-status {
		flex-direction: row;
		align-items: center;
		padding: 4px 12px;
		background: #fbb739;
		border-radius: 25px;
		color: #ffffff;
	}
	.ticket-refund {
		color: #09b2aa;
	}
	.custom-row-hover:hover td {
		background: #feefd8 !important;
	}
}

.claim-filter-form {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	& > div {
		margin-right: 24px;
		margin-bottom: 12px;
		&.code {
			margin-right: 0;
		}
	}
	&-group {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		&__input {
			// flex-grow: 1;
		}
	}
}

@media only screen and (min-width: 768px) {
	.ticket-container {
		.claim-filter-title {
			display: flex;

			&:after {
				opacity: 0;
			}
		}
	}
}

@media only screen and (min-width: 1280px) {
	.ticket-container {
		.claim-filter-title {
			display: flex;
			&:after {
				opacity: 0;
			}
		}
		.claim-filter-form-group {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-grow: 1;
			.claim-filter-form-label {
				margin-right: 5px;
				margin-bottom: 0;
			}
			.claim-filter-form-control {
				width: 100%;
			}
			&.code {
				flex-grow: 0;
				min-width: 360px;
			}
			&.accounts {
				margin-right: 0;
			}
		}
		.item-selected {
			margin-bottom: 0;
		}
		.item-non-selected {
			margin-bottom: 0;
		}
	}
}

@media screen and (min-width: 1610px) {
	.ticket-container {
		& .claim-filter-form-group.accounts {
			margin-right: 24px;
		}
	}
}

.order-claims-list {
	& th.ant-table-cell {
		background-color: $txt-color-gray3;
		padding: 0.32em 1em !important;
		border: none;
		&::before {
			background-color: #c7c7c7 !important;
		}
	}
}

