.shipments {
	&-filter {
		background-color: white;
		padding: 12px;
		box-shadow: 0px 2px 14px #e6e6e6;
		border-radius: 6px;
		display: flex;
		flex-direction: column;
		margin-bottom: 12px;
	}

	&-form {
		&__actions {
			display: flex;
			justify-content: space-between;
			align-items: center;
			& .expand,
			& .buttons {
				display: flex;
				align-items: center;
				cursor: pointer;
			}

			& .buttons {
				&-clear {
					display: flex;
					align-items: center;
					cursor: pointer;
					color: #707070;
				}
			}
		}
		& .handlingtime-group {
			display: flex !important;
			& .ant-select-selector {
				width: 100px;
			}
			&-input {
				text-align: center;
				&__split {
					flex: 0 0 30px;
					border-left: 0;
					border-right: 0;
					pointer-events: none;
				}
			}
		}
		& .ant-input-affix-wrapper,
		& .ant-picker,
		& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border-radius: 4px;
			border: 1px solid #c7c7c7;
		}
		& .ant-form-item-label {
			flex-basis: 120px;
			margin-right: 4px;
			& > label {
				font-size: 14px;
				line-height: 22px;
				color: #1a1a1a;
				&[for='shipments-form_supplier'],
				&[for='shipments-form_createdAt'] {
					width: 120px;
				}
			}
		}

		& .ant-form-item.supplier > .ant-form-item-label {
			flex-basis: 124px;
		}
	}

	&-items.ant-list {
		background-color: white;
		// box-shadow: 0px 2px 14px #e6e6e6;
		padding: 12px 16px;
		& .ant-list-header {
			border-bottom: none;
			padding: 4px 12px 16px 12px;
			padding-left: 0;
		}
		& .ant-list-item {
			align-items: center;
			border-radius: 6px;
			border-bottom: none;
			flex-basis: 85%;
			margin-bottom: 12px;

			& .shipment-status {
				flex-basis: 15%;
				flex-grow: 0
			}
			&:hover {
				background: #fff6e5;
			}
			padding: 12px 16px;
			& .ant-list-item-meta-title {
				margin-bottom: 0;
				line-height: 20px;
				font-weight: 400;
				& > div {
					height: 100%;
				}

				& .ant-divider {
					border-left: 1px solid #c7c7c7;
				}
			}

			& .ant-list-item-meta {
				align-items: center;
			}
		}
	}

	&-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		& .ant-empty-description {
			display: flex;
			flex-direction: column;
			& > span {
				margin-bottom: 12px;
			}
		}
	}
}

.txt-capitalize {
	text-transform: capitalize;
}

.site-input-group-wrapper .site-input-split {
	background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
	border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
	border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
	border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
	border-right-width: 1px;
}

