.freeContainer{
  background-color: #FFF6E5;
  // border: 1px solid #F9A400;
  box-sizing: border-box;
  display: flex !important;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
}

._note-icon-custom {
  :hover {
    cursor: pointer;
  }
}
._btn-order-custom {
  border-radius: 4px!important;
  border: 1px solid #FFD7A5!important;
  height: 42px!important;
  background-color: #FFFFFF!important;
  color: #FFA634!important;
  &._btn-order-custom:hover {
    color:white!important;
    background-color: #FFA634!important;
  }
}
.notice-warehouse{
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  /* Lighter */
  background: #FFF6E5;
  margin-top: 12px;

  /* Border */
  border: 1px solid #FFA600;
  box-sizing: border-box;
  border-radius: 6px;
}


.box-step-service {
  height: 500px;
  &.ant-card {
    border-radius: 6px;
    box-shadow: inset 1px 0px 0px #EBEBEB;
    & .box-step-service__left {
      padding: 12px;
      & .ant-select-selector {
        border-radius: 4px;
        & .ant-avatar-image {
          border: none;
        }
      }

      & .label-row {
        display: flex;
        gap: 12px;
        align-items: center;
        margin-bottom: 4px;
        & .line-d-h-1 {
          flex-grow: 1;
          border: 1px solid #EBEBEB;
          background-color:  #EBEBEB;
        }
      }
    }
  }
}

