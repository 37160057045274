.shipment {
	display: flex;
	box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
	background-color: white;
	border-radius: 6px;
	padding: 12px;
	height: 520px;
	& .box {
		border-radius: 0px 6px 6px 0px;
		padding: 0 12px;
		&-divider {
			border-top: 1px solid #ebebeb;
			flex-basis: 100%;
			&-orange {
				border-top: 1px solid #fbb739;
			}
		}
		&-services {
			display: flex;
			flex-direction: column;
			flex-basis: 70%;
			height: 496px;
			padding-left: 0;
			&__label {
				font-size: 16px;
				line-height: 24px;
				color: #1a1a1a;
				font-weight: 500;
				white-space: nowrap;
			}
			&__attach {
				margin-bottom: 12px;
			}

			&__addresses {
				display: flex;
				flex-direction: column;
				&-item {
					display: flex;
					align-items: baseline;
					line-height: 22px;
					font-size: 14px;
					line-height: 22px;
					color: #1a1a1a;
					& > i {
						color: #707070;
						margin-right: 12px;
					}
					& .receiver {
						font-weight: 500;
					}
				}
				&-notice {
					display: flex;
					padding: 8px;
					background: #fff6e5;
					border: 1px solid #ffa600;
					border-radius: 6px;
					margin-top: 12px;
					align-items: center;
					& > i:first-of-type {
						color: #faa200;
					}
					position: relative;
					& > i:last-of-type {
						position: absolute;
						top: 12px;
						right: 12px;
					}

					& .content {
						font-size: 14px;
						line-height: 22px;
						color: #1a1a1a;
						margin-top: 0;
						padding: 0;
						background-color: unset;
						padding-right: 16px;
					}
				}
			}

			&__note {
				display: flex;
				flex-direction: column;
				&-item {
					display: flex;
					align-items: center;
					& .label {
						font-size: 12px;
						line-height: 20px;
						color: #707070;
					}
				}
			}
			& .connections-selection {
				display: flex;
				justify-content: flex-end;
				margin-bottom: 12px;
				& .ant-select {
					width: fit-content;
				}
			}
		}

		&-fees {
			flex-basis: 30%;
            display: flex;
			padding-right: 0;
            flex-direction: column;
            justify-content: space-between;
			border-left: 1px solid #ebebeb;
			height: 496px;
            &__shipment-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

			&__details {
				& .label, & .value {
					font-size: 14px;
					line-height: 22px;
				}

				& .label, & .value {
					color: #1A1A1A;
				}
			}
		}
	}

	.ant-btn-text:hover, .ant-btn-text:focus {
		background: transparent;
		color: #F59F32;
	}
	.empty-address .ant-empty-description {
		display: flex;
		flex-direction: column;
		align-items: center;
		& > button {
			width: 141px;
		}
	}
}

.form-shipment {
	& .ant-form-item-label {
		flex-basis: 145px;
		& > label {
			flex-direction: row-reverse;
			font-size: 14px;
			line-height: 22px;
			color: #707070;
		}
	}

    & .ant-form-item-control-input-content input {
        border: 1px solid #C0C0C0;
        border-radius: 4px;
		padding: 4px 12px;
		line-height: 22px;
		&:hover {
			border-color: #ffce63;
			border-right-width: 1px;
		}
	
		&:placeholder-shown {
			font-size: 14px;
			line-height: 22px;
		}
    }
	.ant-form-item-label {
		& > label {
			color: #1A1A1A;
			&.ant-form-item-no-colon {
				&::after{
					margin: 0;
				}
			}
		}
	}
}

