// body {
//   font-family: 'Roboto', sans-serif;
//   color: #1A1A1A;
// }
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}
.splash-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.border-card-header{
  box-shadow: 0px 2px 14px #E6E6E6;
  background-color: #FFFFFF;
}
.title-body{
  font-size: 20px;
  color: #1A1A1A;
  font-weight: 500;
}
.icon-empty{
  width: 40px;
  height: 40px;
}

.ant-tabs-nav-list {
  font-size: 16px;
  line-height: 24px;
  color: #707070
}

.divider-bd-color {
  border-color:#EBEBEB;
}
.main-layout .content {
  background-color: #EDF3F7;
}
.floatIcon{
  position: fixed;
  bottom: 33px;
  right: 20px;
  display: flex;
  align-items: flex-end;
}

@import 'helpers/variables';
@import 'helpers/mixins';
@import "module/common";
@import "module/form";
@import "module/button";
@import "module/table";
@import "module/popup";
@import "module/info-group";
@import "module/rate";
@import "module/badge";
@import "module/input";
@import "screens/claim";
@import "screens/claim-detail";
@import "module/_antd-override";
@import "screens/profile";
@import "screens/connect-market";
@import "screens/stored-products.scss";
@import "module/_cart.scss";
@import "screens/profile-service";
