.divider-bd-custom {
	border: 1px solid $bd-divider-success-box;
}
.faq-modal {
	.ant-modal-header {
		padding-top: 12px;
		padding-bottom: 12px;
		& .ant-modal-title > span {
			color: #1a1a1a !important;
		}
	}
	.ant-modal-footer {
		padding-bottom: 12px;
	}
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder > td {
	border-bottom: none;
	padding-bottom: 4px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-radius: 4px;
	border: 1px solid #c7c7c7;
}

.ant-modal-content {
	.ant-modal-header {
		padding-top: 12px;
		padding-bottom: 12px;
		& .ant-modal-title {
			color: #1a1a1a;
		}
	}
	& .ant-modal-footer {
		& > button {
			padding: 5px 16px;
			text-transform: capitalize;
		}
	}
	& .ant-modal-footer {
		padding-bottom: 12px;
	}
}

.ant-empty-image {
	height: auto;
}



.data-row_extended {
	// pointer-events: none;
	cursor: pointer;

	&:hover > td {
		background-color: none !important;
	}
}

.ant-breadcrumb {
	font-size: 14px;
	line-height: 22px;

	& > ol {
		align-items: center;
		& > li {
			display: flex;
			align-items: center;
			& .ant-breadcrumb-separator {
				margin: 0 4px;
			}
			&:last-of-type {
				margin-right: 4px;
				& .ant-breadcrumb-link * {
					color: #1a1a1a;
					font-weight: 500;
				}
				& .ant-breadcrumb-separator {
					display: none;
				}
			}
		}
	}
}

.ant-popover-inner-content {
	padding: 12px;
	color: #1a1a1a;
}

.ant-list-item-meta-avatar {
	margin-right: 12px;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
	background: transparent;
	color: #f59f32;
}

.ant-input {
	background: #ffffff;
	border: 1px solid #c7c7c7;
	border-radius: 4px;
	font-weight: 400;
	&:placeholder {
		color: #707070;
		font-size: 12px;
	}
}
.ant-picker {
	border-radius: 4px;
	border: 1px solid #c7c7c7;
}

.ant-tooltip-inner {
	background-color: #fbb739;
	color: white;
}
.ant-tooltip-arrow-content::before {
	background: #fbb739;
}

.gsa-tooltip {
	.ant-tooltip-arrow-content::before {
		display: none;
	}
	& .shoppe-gsa-link a {
		color: #1a1a1a;
		&:hover {
			color: #fbb739;
		}
	}
}

.ant-menu-inline, .ant-menu-vertical {
	border-right: 0;
}

.ant-menu-vertical {
	&.ant-menu-inline-collapsed {
		padding-left:8px !important;
		padding-right:8px !important;
		& .icon {
			padding-top: 8px;
			padding-bottom: 8px;
			border-radius: 6px;
		}
	}
}
.ant-menu-submenu-inline {
	border-bottom: 1px solid #FFD78A;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-item {
	padding-left: 16px !important;
	padding-right: 0 !important;
}

.ant-input-affix-wrapper {
	border-radius: 4px;
	border: 1px solid #c7c7c7;
}

.ant-radio {
	line-height: 22px;
	top: 2px;
}

.link-override {
	&:hover {
		color: #fbb739;
	}
}

.ant-upload-list-item-actions {
	display: flex;
	align-items: baseline;

	& .anticon.anticon-eye {
		vertical-align: 0;
		height: 18.858px;
		line-height: 1;
		& > svg {
			height: 18.858px;
		}
	}
}
.ant-upload-list-item-card-actions-btn {
	& .anticon.anticon-delete {
		height: 18.858px;
		& > svg {
			height: 18.858px;
		}
	}
}

.ant-picker-input > input:placeholder-shown, .ant-input:placeholder-shown {
	color: #707070;
	font-size: 14px !important;
	font-weight: 400 !important;
	padding-left: 12px;
	padding-right: 12px;
}

.ant-table-measure-row {
	// display: none;
}

.ant-tooltip-inner {
	font-size: 12px;
	font-weight: 500;
	color: white;
	border-radius: 6px;
	box-shadow: none;
	
}
.ant-tooltip-arrow-content {
	box-shadow: none !important;
}
.ant-table-title {
	border-radius: 6px 6px 0 0;
	padding: 16px 8px;
}

.ant-table-cell.ant-table-selection-column {
    border-spacing: initial;
}

.ant-select-selection-placeholder {
	font-size: 14px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	padding-left: 12px;
	padding-right: 12px;
	// line-height: 32px;
	// height: 32px;
	color: #707070;
	font-size: 14px;
	line-height: 35px;
	height: 35px;
}

.ant-layout-sider-trigger {
	background-color: #fbb739 !important;
	& .anticon-logout {
		color: white !important;
		
	}
	box-shadow: 0px -5px 12px rgba(224, 151, 17, 0.3);
}
