.merchant-card-bottom {
	background-color: #ffffff !important;
	box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
	position: fixed;
	bottom: 0;
	left: 65px;
	width: calc(100% - 80px);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 24px;
	z-index: 99;
}
.merchant-card-title {
	position: fixed;
	top: 0;
	.ant-card-body {
		padding: 0;
	}
}

.merchant-card-custom {
	background-color: #ffffff !important;
	padding: 0;
	margin-bottom: 12px !important;
	&.ant-card {
		border-radius: 4px;
		& .ant-card-head,
		& .ant-card-body {
			padding: 8px 12px;
		}

		& .ant-card-head {
			margin-bottom: 0;
			padding-bottom: 5px;
			min-height: 36px;
			&-title {
				padding: 0;
				line-height: 20px;
			}
		}

		& .ant-card-body {
			& .ant-list-item {
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}
	}

	&:hover {
		&.ant-card {
			border-color: #ffa600;
			& .ant-card-head {
				background-color: #ffeed9;
			}
		}
	}
	&.active {
		&.ant-card {
			border-color: #ffa600;
			& .ant-card-head {
				background-color: #ffeed9;
			}
		}
		& .ant-btn:not([disabled]) {
			background-color: transparent;
		}
	}
}
.button-delete-all {
	height: 40px !important;
	width: 160px;
	:hover {
		border-color: red !important;
		& > i,
		& > span {
			color: red !important;
		}
	}
}

.ant-btn:not([disabled]) {
	&.button-delete-all {
		border: none;
		// background-color: white;
		background: transparent;
		&:hover,
		&:active,
		&:focus {
			& > i,
			& > span {
				color: red !important;
				background: none !important;
			}
		}
	}
}

.button-order-custom {
	height: 40px !important;
	width: 160px;
}

