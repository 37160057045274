.text-copy {
  &.ant-typography {
    display: flex;
    justify-content: space-between;
    color: #1a1a1a;
    margin-bottom: 0;

    a,
    span {
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
  }
}
