.ant-table {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.product-list,
.package-list,
.delivery-request {
	.ant-table {
		.ant-table-thead > tr > th {
			font-family: Roboto;
			font-weight: 500;
			font-size: 14px;
			background-color: #f2f2f2;
			padding: 5px 12px;
			border-bottom: none;
			color: #707070;
			white-space: nowrap;
			&::before {
				background-color: #c7c7c7 !important;
			}
		}
		tbody {
			td {
				vertical-align: top;
				border-bottom: 1px solid #ebebeb;
				padding: 8px 12px;
				& .ant-badge-status-text {
					font-size: 14px;
					color: #1a1a1a;
					font-family: 'Roboto', sans-serif;
				}
			}
			tr {
				&:last-child > td {
					border-bottom: none !important;
				}
			}
		}
	}
	&.extended {
		.ant-table {
			tbody {
				td {
					vertical-align: top;
					border-bottom: 1px solid #ebebeb;
					padding: 12px;
					border: 1px solid #ebebeb;
				}
			}
		}
	}
}

.product-list {
	.ant-table tbody td {
		vertical-align: middle;
	}
	.ant-table tbody tr:last-child td {
		border-bottom: none;
	}
}
.package-list {
	&.table-expandable {
		&_has-milestone {
			& .ant-table tbody .ant-table-expanded-row td {
				padding-bottom: 8px !important;
				border-bottom: 1px solid #ebebeb;
			}
		}
		& .ant-table.ant-table-small tbody .ant-table-expanded-row td:first-of-type {
			padding-right: 0 !important;
			padding-top: 0 !important;
			padding-left: 0 !important;
			// padding-bottom: 0 !important;
			// background-color: white;
		}
		& .ant-table.ant-table-small tbody .ant-table-expanded-row .package-list-waybill .package-data td:first-of-type {
			padding: 5px 12px !important;
		}

		& .ant-table-expanded-row {
			& > td {
				background-color: #fff6e5;
				// border-bottom: 1px solid #EBEBEB !important;
			}
			background-color: #fff6e5;
		}
	}
	.ant-table.ant-table-small {
		.ant-table-thead > tr > th {
			padding: 6px 16px;
			&:first-of-type {
				padding-left: 12px;
			}
		}

		tbody {
			td {
				&:first-of-type {
					padding-left: 12px !important;
				}

				padding: 5px 16px !important;
				border-bottom: none;
			}
		}
	}

	&-waybill {
		background-color: #fff6e5;
		.ant-table.ant-table-small .ant-table-tbody {
			background-color: #fff6e5;
		}
		.ant-table.ant-table-small .ant-table-thead > tr > th {
			background: #fff6e5;
			color: $txt-color-blue6;
			&::before {
				width: 0 !important;
			}
		}

		& .ant-table-measure-row {
			// dis
			padding: 0;
			height: 0;
		}
	}
}
.order-transaction-list {
	.ant-table-thead > tr > th {
		padding: 5px 16px;
		background-color: #f2f2f2;
		white-space: nowrap;
	}
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		background-color: #c7c7c7;
	}
	.ant-table-tbody > tr > td {
		padding: 9px 16px;
	}
}

.main-table {
	z-index: 0;
	&__nested-pink {
		.ant-table.ant-table-empty table tbody tr.ant-table-placeholder:hover > td { 
			background-color: #fff6e5 !important;
		}
	}
	&-orange-hovered {
		.ant-table {
			.ant-table-tbody > tr.ant-table-row:hover > td {
				background: $bg-color-pink2;
			}
		}
	}
	&.selected-table {
		tr {
			&.active-row {
				& > td {
					background-color: $bg-color-orange4 !important;
					border: none !important;
				}
				&::hover {
					& > td {
						// background-color: $bg-color-orange4 !important;
						background-color: #ffeece !important;
					}
				}
			}
		}
	}
	.ant-table {
		table > tbody > tr {
			& > td {
				&.ant-table-cell {
					vertical-align: middle;
					border-bottom: 1px solid #ebebeb;
					padding: 12px;
					white-space: nowrap;
				}
			}
			&::hover {
				background-color: #fafafa;
			}
		}
		&.ant-table-empty {
			table tbody tr {
				&:hover {
					& > td {
						background-color: white !important;
					}
				}
			}
		}
		& .ant-table-thead {
			tr > th {
				&.ant-table-cell {
					background-color: $bg-color-gray-tab !important;
					padding: 6px 12px;
					border-bottom: none;
					color: #707070 !important;
					white-space: nowrap;
					&::before {
						width: 0 !important;
					}
					&.ant-table-row-expand-icon-cell {
						padding: 0 !important;
						width: 0 !important;
						padding-left: 12px;
					}
				}
			}
		}

		& .ant-table-title {
			padding-top: 0;
		}
		table {
			tbody {
				tr {
					&::hover {
						background: red !important;
						td {
							&.ant-table-cell {
								background: red !important;
							}
						}
					}
					&.active-row {
						background-color: $bg-color-pink1;

						&::hover {
							& > td {
								background-color: $bg-color-pink1;
								border: none !important;
							}
						}
					}
					&.ant-table-expanded-row {
						& > td {
							background-color: $bg-color-pink2;
							border: none !important;
						}
					}
					td {
						&.ant-table-cell {
							vertical-align: middle;
							border-bottom: 1px solid #f5f5f5;
							padding: 12px;
							&.ant-table-row-expand-icon-cell {
								// width: 0 !important;
								// padding: 0 !important;
							}
						}
					}
				}
			}
		}
	}
	&.nested-px {
		&-36 {
			.ant-table-expanded-row > td {
				padding-left: 36px !important;
			}
		}
		&-48 {
			.ant-table-expanded-row > td {
				padding-left: 72px !important;
			}
		}
		&-74 {
			.ant-table-expanded-row > td {
				padding-left: 112px !important;
			}
		}
	}
	&__nested {
		&-pink {
			.ant-table .ant-table-thead tr > th.ant-table-cell {
				background-color: #fff6e5 !important;
			}
			
		}
		& .ant-table {
			background-color: #fff6e5;
			& .ant-table-row:not(:last-child) {
				& .ant-table-cell {
					border-bottom: 1px dashed #d2c5c5 !important;
				}
			}
		}
		table {
			width: auto;
		}
	}
}
.table-expandable {
	& .ant-table table tbody tr td.ant-table-cell.ant-table-row-expand-icon-cell {
		padding-left: 4px;
		padding-right: 4px;
	}

	&.package-list {
		& .ant-table table tbody tr td.ant-table-cell.ant-table-row-expand-icon-cell {
			// padding-left: 0 !important;
			// padding-right: 0 !important;
		}
		.ant-table.ant-table-small .ant-table-thead > tr {
			& > th {
				font-family: 'Roboto', sans-serif;
				font-size: 14px;
				line-height: 22px;
				color: #1a1a1a;
				font-weight: 500;
				&:first-of-type {
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
				&:nth-child(2) {
					padding-left: 0 !important;
				}
			}
		}
		.ant-table.ant-table-small .ant-table-tbody > tr {
			& > td:nth-child(2) {
				padding-left: 0 !important;
			}
		}
	}
	.ant-table-expanded-row {
		& > td {
			padding: 0px auto !important;
			& .ant-table {
				margin: 0 !important;
				.ant-table-thead > tr > th.ant-table-cell {
					background-color: #fff6e5;
					&::before {
						width: 0;
					}
				}
				td {
					padding: 6px 12px !important;
					background-color: $bg-color-pink2;
					&::hover {
						background-color: unset;
					}
					border-bottom: none;
				}
			}
		}
	}

	&-bordered {
		.ant-table-expanded-row {
			& > td {
				& .ant-table {
					& .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody {
						& > tr:last-child > td {
							border-bottom: none !important;
						}
						& > tr > td:not(:last-child) {
							border-right: none !important;
							border-bottom: 1px solid #ececec !important;
						}
					}
				}
			}
		}
	}
}

.ant-table-selection-col,
.ant-table-expand-icon-col {
	// width: 16px;
}

.table-improved {
	& .ant-table-content {
		& .ant-table-thead {
			& > tr > th {
				padding-top: 6px;
				padding-bottom: 6px;
				font-size: 12px;
				line-height: 20px;
				white-space: nowrap;
				font-weight: 500;
				border-bottom: none;
				background-color: #f4f4f4;
				&::before {
					width: 0 !important;
				}
				&:first-of-type {
					border-top-left-radius: 6px !important;
				}

				&:last-of-type {
					border-top-right-radius: 6px !important;
				}
			}
		}

		& .ant-table-tbody {
			&::before {
				content: '-';
				display: block;
				line-height: 1em;
				color: transparent;
			}
			& > tr > td {
				// border-radius: 8px;
				vertical-align: middle;
				border-bottom: none;
				padding-bottom: 0;

				&:first-child {
					border-top-left-radius: 6px;
					// border-bottom-left-radius: 6px;
				}
				&:last-child {
					border-top-right-radius: 6px;
					// border-bottom-right-radius: 6px;
				}
			}
			& .ant-table-expanded-row {
				& > td {
					padding: 17px 50px;
					background-color: $bg-color-pink6;
					border-bottom: 0 !important;
					&:last-child {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					}
				}
				& .shipment-expand-row {
				}
			}
			& .active-row {
				border-radius: 8px !important;
				& > td {
					border-bottom: 0 !important;
				}
			}
		}

		.ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover > td,
		.ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover + tr > td {
			background-color: $bg-color-pink2 !important;
			& > i {
				color: $txt-color-red;
			}
		}
		tr.ant-table-row.data-row_extended:hover > td {
			background-color: unset;
		}
	}

	& .ant-table {
		border-radius: 6px;
		padding: 0 16px 16px 16px;
		& .ant-table-title {
			padding-left: 0;
			padding-right: 0;
		}
		& .ant-table-content {
			// padding: 0 16px;
		}
	}
}

