.stored-products-container {
  & .ant-tooltip-arrow-content::before {
    width: 30px;
  }

  & .ant-card {
    padding: 12px;
    border-radius: 6px;
    background: $bg-color-white;
    @include css3-prefix(box-shadow, 0px 6px 15px rgba(0, 0, 0, 0.1));

    &-bordered {
      border: none;
    }

    & > * {
      padding: 0;
    }

    & .ant-card-head {
      border-bottom: unset;
      min-height: unset;
      margin-bottom: 8px;

      & .ant-card-head-title,
      & .ant-card-head-title h3 {
        &.ant-card-head-title {
          overflow: unset;
        }

        padding: 0;
        line-height: 24px;
        color: $txt-color-black;
        font-size: $txt-size-h5;
        font-family: $robotofont;
      }
    }
  }

  & .filter-list-container {
    &.collapse {
      & .ant-card-head {
        margin-bottom: 0;
      }
    }
    & .btn-option {
      padding: 1px 10px;
      background: $bg-color-pink2;
      border-radius: 4px;
      border: none;
      line-height: 0;
      height: unset;
      @include css3-prefix(box-shadow, unset);

      & > span {
        line-height: 20px;
        font-size: $txt-size-h8;
        font-weight: 500;
        font-family: $robotofont;
        color: $txt-color-orange1;
      }

      &-selected {
        background: $txt-color-orange3;

        & > span {
          color: $txt-color-white;
        }
      }

      &:hover {
        background: $txt-color-orange3;

        & > span {
          color: $txt-color-white;
        }
      }
    }

    & .ant-input {
      border: 1px solid $txt-color-gray6;

      &:hover {
        border: 1px solid #ffce63;
      }

      &:focus {
        border: 1px solid $bd-divider-success-box;
      }

      &:placeholder-shown {
        color: $txt-color-secondary;
        font-size: $txt-size-h8;
        font-family: $robotofont;
      }

      &.input-fixed-width {
        width: 300px;
      }
    }

    & .ant-input-affix-wrapper {
      border: 1px solid $txt-color-gray6;
      padding-top: 0;
      padding-bottom: 0;
      height: 28px;
      & > input {
          border: none;
          &:focus, &:active, &:hover {
            border: none;
          }
      }
    }

    & .btn-filter-reset {
      & > i {
        margin-right: 4px;
        @include css3-prefix(transform, rotateY(180deg));
        font-weight: 400;
        font-size: $txt-size-h9;
      }

      & > span {
        color: inherit;
        font-size: inherit;
      }

      &:hover {
        background: none;

        & span {
          color: inherit;
        }
      }
    }

    & .btn-submit {
      border: 1px solid $bg-color-blue7;
      background: $bg-color-blue7;
      color: $txt-color-white;
      padding: 3px 28px;

      & > span {
        line-height: inherit;
      }

      &:hover {
        @include css3-prefix(box-shadow, 0px 0px 0px 3px #CED1F3);
      }
    }
  }

  & .product-list-container {
    & .ant-badge {
      & .ant-badge-count {
        font-size: $txt-size-h8;
        font-weight: 500;
        font-family: $robotofont;
        color: $txt-color-white;
        border-radius: 20px;
        padding: 0 8px;
      }

      &.badge-not-zero .ant-badge-count {
        background: $bg-color-orange3;
      }

      &.badge-zero .ant-badge-count {
        background: $bg-color-gray7;
      }
    }

    & .btn-sale,
    & .btn-remove-product {
      border: none;
      padding: 5px 16px;
      @include css3-prefix(box-shadow, 0px 0px 0px 3px transparent);

      & i {
        font-weight: bold;
      }

      & span {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        margin-left: 8px;
        font-weight: inherit;
        font-family: inherit;
      }

      &.btn-remove-product {
        background: $txt-color-red2;

        &:hover {
          @include css3-prefix(box-shadow, 0px 0px 0px 3px #FFB2BA);
          color: $txt-color-white;

          & i {
            color: $txt-color-white;
          }
        }
      }

      &:disabled {
        background: $bg-color-gray5;
        color: $txt-color-gray2;
        border: 1px solid $bd-disabled;

        &:hover {
          box-shadow: none;
          color: $txt-color-gray2;

          & i {
            color: $txt-color-gray2;
          }
        }
      }
    }

    & .ant-table {
      &-empty {
        & .ant-table-tbody > tr > td {
          @include css3-prefix(box-shadow, unset !important);
        }
      }

      & .ant-table-thead {
        & > tr > th {
          padding: 10px 12px;
          border-bottom: unset;
          background: $bg-color-gray-tab;

          &::before {
            display: none;
          }

          color: $txt-color-black;
          font-weight: 500;
          font-size: $txt-size-h8;
          font-family: $robotofont;

          & span {
            color: $txt-color-black;
            font-weight: 500;
            font-size: $txt-size-h8;
            font-family: $robotofont;
          }
        }

        & .ant-checkbox {
          & .ant-checkbox-inner {
            width: 12px;
            height: 12px;

            &::after {
              left: 50%;
              top: 50%;
              @include css3-prefix(transform, translate(-50%, -50%));
              width: 5px;
              height: 2px;
              background: white;
            }
          }
        }
      }

      & .ant-table-tbody {
        & > tr > td {
          padding: 10px 12px;
          border-bottom: unset;
          @include css3-prefix(box-shadow, inset 0px -1px 0px #F5F5F5);

          &.ant-table-cell-row-hover {
            background: #FEEFD8;
            cursor: pointer;
          }

          & .ant-btn {
            padding: 0;

            &.ant-btn-icon-only {
              width: auto;
              height: auto;
            }
          }
        }

        & .ant-table-row-selected > td {
          & button {
            pointer-events: none;

            & i {
              color: $txt-color-gray2;
              pointer-events: inherit;
            }
          }
        }

        & .ant-checkbox-inner {
          width: 12px;
          height: 12px;

          &::after {
            $icon-width: 4px;
            width: $icon-width;
            @include calc(height, $icon-width * (9.14285714 / 5.71428571));
          }
        }
      }

      .ant-table-selection-column {
        width: 2%;
      }
    }

    & .pagination-container {
      & .ant-row {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &.waiting-products-container .product-list-container {
    & .ant-table .ant-table-tbody > tr > td {
      vertical-align: text-top;

      &:nth-child(5) {
        padding-left: 0;
        padding-right: 0;
      }

      & span.shop-name {
        border: 1px solid $txt-color-blue6;
      }

      & .marketplace-row,
      & .product-code,
      & .action-row {
        position: absolute;
        //top: $table-cell-spacing-y;
      }
    }
  }
}

.action-tooltip {
  & .ant-tooltip-arrow-content {
    transform: translateY(-13px) rotate(45deg);
  }
}

.pendingProducts-data {
  thead[class*="ant-table-thead"] th {
    padding: 7px 12px !important;
    border: none;
    background-color: #F4F6F8!important;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;

    &:before {
      opacity: 0;
    }
  }

  .ant-table-cell {
    padding: 12px 6px;
  }
}

.post-to-sale-container {
  color: #1A1A1A;
  .ant-modal-header {
    border: 1px solid transparent!important;
    padding: 16px 0 0 0;
  }
  .ant-modal-header .ant-modal-title {
    font-weight: 500;
    font-size: 14px;
  }

  .ant-modal-body {

    .market-integration {
      &__channel {
        .ant-card-head {
          min-height: 30px!important;
          background-color: #F4F6F8;
        }

        .ant-card-head-title {
          padding: 6px 0;
          .ant-checkbox-wrapper {
            align-items: center;
          }
        }

        .ant-card-body {
          padding: 0;
        }
        .ant-checkbox-wrapper {
          width:100%;
        }
      }

      &__item {
        font-weight: 500;
        font-size: 14px;

        .ant-list-item {
          padding:8px 12px;
        }

        &::-webkit-scrollbar {
          width: 4px;
          display: flex;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(196, 196, 196, 0.6);
          background: #C7C7C7;
          border-radius: 20px;
        }
      }
    }

  }

  .ant-modal-footer {
    margin: 15px 0 0 0;
    padding: 0;
    .submit {
      border: 1px solid transparent !important;
      background: #3340B6;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      padding: 5px 16px;
      color: #FFFFFF;
    }

    .submit:hover {
      box-shadow: 0 0 0 3px #CED1F3;
    }
    .submit:disabled {
      align-items: center;
      padding: 5px 16px;
      background: #F5F5F5;
      border: 1px solid #C7C7C7!important;
      color: #707070;
      &:hover {
        box-shadow: none;
      }
    }

    .cancel {
      border: 1px solid #C7C7C7;
      border-radius: 4px;
      background: #FFFFFF;
      align-items: center;
      padding: 5px 16px;
      color: #1A1A1A;
    }

    .cancel:hover {
      background-color: #C7C7C7;
      color: #FFFFFF;
    }
  }
  .navigate-to-pending-list {
    cursor: pointer;
    color:#1890ff;
    font-weight:400;
    font-size:12px;
    display: flex;
    align-items: center;
    &:hover {
      color: $txt-color-primary;
    }
  }
  .moving-item-progress-bar {
    .ant-progress-inner{
      height: 24px;
    }
    .ant-progress-inner .ant-progress-bg {
      height:24px!important;
      color: #3340B6;
    }
  }
  .btn-action-force {
    border: 1px solid transparent !important;
    background: #3340B6;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    &:hover {
      box-shadow: 0 0 0 3px #CED1F3;
    }
  }
  .ant-modal-confirm-btns {
    align-items: center;
    justify-content: right;
    display: flex;
  }

}
