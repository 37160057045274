.bt-status{
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  //display: flex;
  height: 20px;
  left: 12px;
  top: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	background-color: #3340B6 !important;
}

.orders-list {
  
}

.orders-data-shows {
  & .ant-list-header {
    padding: 16px 4px;
    border-bottom: none;
  }

  & .ant-list-items {
    padding-left: 0;
  }
}
